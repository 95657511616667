<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">

    <!--begin::Notifications-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">

      <span class="menu-title">
        {{ this.tanggalDanWaktuSekarang }} 
      </span>

      <!--begin::Menu-->
      <!-- <span class="switch switch-icon">
                  <input type="hidden"  />
                  <div
                    class="form-check form-check-custom form-check-solid form-switch mb-2"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      true-value="dark"
                      false-value="light"
                      style="height:20px !important;max-width: 35px;"
                      v-model="aside"
                      @change="changeAside"
                    />
                  </div>
        </span> -->
      <!--end::Menu-->
    </div>
    <!--end::Notifications-->

    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img :src="user != null ? $mix.generateAvatar(user.data.vendor_name) : '#'" alt="metronic" />
      </div>
      <KTUserMenu></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->

    <!--begin::Heaeder menu toggle-->
    <!-- <div
      class="d-flex align-items-center d-lg-none ms-2 me-n3"
      title="Show header menu"
    >
      <div
        class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
        id="kt_header_menu_mobile_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="/media/icons/duotune/text/txt001.svg" />
        </span>
      </div>
    </div> -->
    <!--end::Heaeder menu toggle-->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script>
import KTSearch from "@/layout/header/partials/Search.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { config } from "@/core/helpers/config";
export default {
  name: "topbar",
  components: {
    KTSearch,
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
  },
  data(){
    return { 
      config:config,
      aside:'',
      user:JSON.parse(this.$store.getters.currentUser),
      tanggalDanWaktuSekarang: "",
    }
  },
  created(){
      localStorage.setItem("config", JSON.stringify(this.config));
      let a = JSON.parse(localStorage.getItem('config'))
      this.aside = a.aside.theme

      this.setTanggalDanWaktuSekarang();
      setInterval(this.setTanggalDanWaktuSekarang, 1000); // Update setiap detik
  },
  methods: {
    setTanggalDanWaktuSekarang() {
      let sekarang = new Date();
      let hari = sekarang.toLocaleString('en-US', { weekday: 'long' });
      let tanggal = sekarang.getDate();
      let bulan = sekarang.toLocaleString('en-US', { month: 'long' });
      let tahun = sekarang.getFullYear();
      let jam = sekarang.getHours();
      let menit = sekarang.getMinutes();
      if(menit < 10) menit = '0' + menit;

      this.tanggalDanWaktuSekarang = `${hari}, ${tanggal} ${bulan} ${tahun} ${jam}:${menit}`;
    },
    changeAside(){
      this.config.aside.theme = this.aside
      localStorage.setItem("config", JSON.stringify(this.config));
    }
  }
}
</script>
