
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const name_app = process.env.VUE_APP_FULL_NAME
    return {
      footerWidthFluid,
      name_app
    };
  },
});
