
import { defineComponent, computed, onMounted, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
import KTInviteFriendsModal from "@/components/modals/general/InviteFriendsModal.vue";
import KTDemosDrawer from "@/layout/extras/DemosDrawer.vue";
import KTHelpDrawer from "@/layout/extras/HelpDrawer.vue";
import KTToolButtons from "@/layout/extras/ToolButtons.vue";
import KTDrawerMessenger from "@/layout/extras/MessengerDrawer.vue";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

import Watermark from '@/components/Watermark.vue'

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    KTCreateApp,
    KTInviteFriendsModal,
    KTUserMenu,
    KTDemosDrawer,
    KTHelpDrawer,
    KTToolButtons,
    KTDrawerMessenger,
    KTLoader,
    Watermark,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.replace({ name: "sign-in" });
      }

      if(store.getters.isMaintananceWeb) {
        router.push({ name: "error-mt" });
      }

      if(store.getters.getError) {
        router.push({ name: "500" });
      }
      if(store.state.AuthModule.firstLogin){
        store.commit(Mutations.SET_FIRST_LOGIN)
      }
      // store.watch(
      //     (state) => {
      //         return state.BodyModule.isPageActive
      //     },
      //     (val)=>{
      //         if(val){
      //           return true
      //         }else{
      //           router.push('/dashboard')
      //         }
      //     },
      //     {
      //         deep:true
      //     }
      //   )
      if(!store.state.BodyModule.isPageActive){
        router.push('/dashboard')
      }

      
      if (store.state.BodyModule.isPageProfile) {
        router.push({ name: "profile" });
      }

      nextTick(() => {
        reinitializeComponents();
      });
        
      // Simulate the delay page loading
      // Remove page loader after some time
      setTimeout(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);
          // store.commit(Mutations.MIDDLEWARE_PAGE, route.path)

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.replace({ name: "sign-in" });
        }

        if(store.getters.isMaintananceWeb) {
          router.push({ name: "error-mt" });
        }

        if(store.getters.getError) {
          router.push({ name: "500" });
        }
      
      if(!store.state.BodyModule.isPageActive){
        router.push('/dashboard')
      }
      if (store.state.BodyModule.isPageProfile) {
        router.push({ name: "profile" });
      }
        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
