<template>
  <!--begin::Menu wrapper-->
  <div
    class="header-menu align-items-stretch"
    data-kt-drawer="true"
    data-kt-drawer-name="header-menu"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
    data-kt-place="true"
    data-kt-place-mode="prepend"
    data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
  >
    <!--begin::Menu-->
    <div
      class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
    >
      <div class="menu-item menu-lg-down-accordion me-lg-1">
        <span class="py-3 pt-5">
          <span class="menu-title">
            <!-- <span class="fw-bolder fs-6 text-dark">VENDOR SITE : {{ profile != null ? profile.data.vendor_type_name : ''}}</span> -->
            <!-- <h2 class="fw-bolder"> -->
            Vendor Site :
            <b> {{ profile != null ? profile.data.vendor_type_name : "-" }} </b>
            <!-- </h2> -->
          </span>
          <span class="menu-arrow d-lg-none"></span>
        </span>
      </div>
      <!--end::Menu-->
    </div>
  </div>
  <!--end::Menu wrapper-->
</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import PageTitle from "@/components/PageTitle.vue";
export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      profile: JSON.parse(this.$store.getters.currentUser),
    };
  },
  mounted() {},
  methods: {
    fetchMe() {
      this.$apiservice.setHeader();
      this.$apiservice.get("/user/v1/me").then((res) => {
        let data = res.data;
        window.localStorage.setItem("user", JSON.stringify(data));
        this.$router.go(this.$router.currentRoute);
      });
    },
  },
};
</script>
